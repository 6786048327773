import { BaseRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/BaseRepository";

export class ConsultationRepository extends BaseRepository {
  constructor() {
    super();

    this.resource = "consultations";
  }

  /**
   * @typedef {Object} Consultation
   * @property {number} id
   * @property {number} customer_id
   * @property {number} seller_id
   * @property {number} seller_service_id
   * @property {number} chat_room_id
   * @property {string} last_status
   * @property {string} last_status_reason
   * @property {number} price
   * @property {Date} start_date
   * @property {Date} end_date
   * @property {string} application
   * @property {Date} created_at
   * @property {Date} updated_at
   */

  /**
   * Get the specified resource
   *
   * @param {number} id
   * @param {string} params
   *
   * @returns {JSON}
   */
  async getById(id, params = null) {
    // TODO: Change API call to /v1/consultations/id
    const {
      data: [consultation],
    } = await this.httpClient.get(
      `v1/${this.resource}?filters[]=id,=,${id}${params}`
    );

    return consultation;
  }

  /**
   * Get all the specified resource
   *
   * @param {number} page
   * @param {number} limit
   * @param {string} name
   *
   * @returns {JSON}
   */
  async getHistory(page, limit, name = null, sellerId) {
    // TODO: Move Consultations Service to not duplicate code
    var url = `v1/${this.resource}/?relationships[]=customer.user&limit=${limit}&page=${page}&order[]=start_date,DESC&filters[]=paginate&filters[]=started_consultations&filters[]=seller_id,=,${sellerId}`;
    if (name) url += `&filters[]=name,${name}`;

    const { data } = await this.httpClient.get(url);

    return data;
  }

  /**
   * Update the specified resource
   * 
   * @param {number | string} id
   * @param {object} payload
   * @returns {Promise<undefined>}
   */
  async update(id, payload) {
    const { data } = await this.httpClient.put(
      `v1/consultations/${id}`,
      payload
    );

    return data;
  }
}
