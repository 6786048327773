
import { useCallStore } from "@astrocentro-webapp/sellers/stores/call";
import { storeToRefs } from "pinia";
import { ConsultationRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/ConsultationRepository";
export class FinishCallConsultationService {
  async perform(consultationId = null) {
    const store = useCallStore();
    const { call } = storeToRefs(store);
    const consultationRepository = new ConsultationRepository();

    if (!call.value) {
      if (consultationId) {
        await consultationRepository.update(consultationId, {
          last_status: 'finished',
          last_status_reason: 'finished-by-seller'
        });
      }

      return;
    }

    call.value.disconnect();
    call.value = null;
  }
}