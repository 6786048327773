<script setup>
import { ref, onMounted, watch, inject, onBeforeMount } from "vue";
import { useOnline } from "@vueuse/core";
import { storeToRefs } from "pinia";

import { VDefaultAvatar } from "@astrocentro-webapp/ui-components/modules/commons/components/VDefaultAvatar";
import { ConsultationTimer } from "@astrocentro-webapp/ui-components/modules/consultation/components/ConsultationTimer";
import { ChatConversationPlaceholder } from "@astrocentro-webapp/ui-components/modules/chat/components/ChatConversationPlaceholder";
import { ChatFeedback } from "@astrocentro-webapp/ui-components/modules/chat/components/ChatFeedback";
import { SetChatChannelStatusService } from "@astrocentro-webapp/commons/modules/chat/services/SetChatChannelStatusService";
import { ChatConnectionStatus } from "@astrocentro-webapp/ui-components/modules/chat/components/ChatConnectionStatus";
import { useBreakpointSmallerThanMd } from "@astrocentro-webapp/ui-components/modules/commons/composables/browser/useBreakpointSmallerThanMd";
import { useCallStore } from "@astrocentro-webapp/sellers/stores/call";
import Mic from "@astrocentro-webapp/ui-components/assets/icons/mic.svg?component";
import MicOff from "@astrocentro-webapp/ui-components/assets/icons/mic-off.svg?component";
import PhoneDown from "@astrocentro-webapp/ui-components/assets/icons/phone-down.svg?component";
import { ConsultationFinishModal } from "@astrocentro-webapp/astrocentro/modules/call/components/ConsultationFinishModal";
import { FinishCallConsultationService } from "@astrocentro-webapp/sellers/modules/call/services/FinishCallConsultationService";
import { SoundAndMicDeviceChangeModal } from "@astrocentro-webapp/astrocentro/modules/call/components/SoundAndMicDeviceChangeModal";
import { CallClient } from "@astrocentro-webapp/commons/modules/commons/clients/CallClient";

const props = defineProps({
  user: {
    type: Object,
    default() {
      return { id: null, username: null };
    },
  },
  isLoading: {
    type: Boolean,
    default: true,
  },
  consultation: {
    type: Object,
    default() {
      return {
        id: 0,
        start_date: "",
        last_status: "",
      };
    },
  },
});

const isLoading = ref(props.isLoading);
const isOnline = useOnline();
const store = useCallStore();
const { call } = storeToRefs(store);
const isMuted = ref(false);
const { isSmallerThanMd } = useBreakpointSmallerThanMd();
const chatConsultationChannelEvents = inject(
  "chat-consultation-channel-events"
);
const setChatChannelStatusService = new SetChatChannelStatusService(
  chatConsultationChannelEvents?.channelName
);

const setData = async (consultationId) => {
  setChatChannelStatusService.perform();

  if (consultationId) {
    isLoading.value = true;
    setTimeout(() => (isLoading.value = false), 2000);
  }
};

const isFinishConsultationModalOpen = ref(false);
const finishChatConsultationService = new FinishCallConsultationService();
const isDeviceChangeModalOpen = ref(false);
const callClient = ref(null);
const handleHangupCall = () => {
  isFinishConsultationModalOpen.value = false;

  if (props.consultation?.last_status === "in-progress")
    finishChatConsultationService.perform(props.consultation?.id);
};

onMounted(() => {
  if (props.consultation?.id) setData(props.consultation?.id);
});

watch(
  () => props.consultation?.id,
  (consultationId) => {
    setData(consultationId);
  }
);

watch(isOnline, () => {
  setChatChannelStatusService.perform();
});

const handleMicrophoneToggle = () => {
  if (call.value) {
    isMuted.value = !isMuted.value;
    call.value.mute(isMuted.value);
  }
};

onBeforeMount(async () => {
  callClient.value = await CallClient.getInstance({
    data: { identity: props.consultation.call_device_id },
  });
});
</script>

<template>
  <div class="h-100">
    <ChatConversationPlaceholder v-if="isLoading" />
    <div class="d-flex justify-content-center align-items-center vh-100" v-else>
      <ChatConnectionStatus
        v-if="
          !isSmallerThanMd &&
          props.consultation &&
          props.consultation?.last_status !== 'finished'
        "
        :channelName="chatConsultationChannelEvents?.channelName"
      />
      <div
        class="text-center py-6"
        v-if="props.consultation?.last_status == 'in-progress'"
      >
        <VDefaultAvatar
          size="lg"
          :name="props.consultation?.customer?.name"
          class="mx-auto mb-4 border border-3 border-gray"
          v-if="props.consultation"
        />
        <h5 class="text-truncate mx-auto">
          {{ props.consultation && props.consultation?.customer?.name }}
        </h5>
        <p
          v-if="user.type != 'customer'"
          class="mb-0 fs-9 text-primary"
          @click="$emit('open-chat-note')"
          role="button"
        >
          Clique para abrir a ficha do cliente
        </p>
        <p
          class="text-muted py-3"
          v-if="
            props.consultation &&
            props.consultation.last_status === 'in-progress'
          "
        >
          <ConsultationTimer
            :start-date="props.consultation.start_date"
            :end-date="props.consultation.end_date"
            :should-stop="!!consultation.end_date"
          />
        </p>
        <div class="mt-5 d-flex justify-content-center">
          <button
            type="button"
            class="btn btn-gray-500 avatar-sm rounded-circle text-white p-0 mx-3"
            @click="handleMicrophoneToggle"
          >
            <Mic v-if="!isMuted" width="24" height="24" />
            <MicOff v-else width="24" height="24" />
          </button>
          <button
            type="button"
            class="btn btn-danger avatar-sm rounded-circle text-white p-0 mx-3"
            @click="isFinishConsultationModalOpen = true"
          >
            <PhoneDown width="24" height="24" />
          </button>
        </div>
        <div class="d-grid mt-6">
          <button
            type="button"
            class="btn btn-outline-gray-600"
            @click="isDeviceChangeModalOpen = true"
          >
            Configurações
          </button>
        </div>
      </div>
      <ChatFeedback
        class="h-100 d-flex align-items-center justify-content-center"
        :userType="props.user.type"
        :consultation="props.consultation"
      />
    </div>
    <SoundAndMicDeviceChangeModal
      :callClient="callClient"
      :open="isDeviceChangeModalOpen"
      @modal-closed="isDeviceChangeModalOpen = false"
    />
    <ConsultationFinishModal
      :open="isFinishConsultationModalOpen"
      @finish-call="handleHangupCall"
      @modal-closed="isFinishConsultationModalOpen = false"
    />
  </div>
</template>
